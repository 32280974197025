.theme-base-08 {
	.sidebar {
		background-color: #ac4142;
	}
	.content a {
		color: #ac4142;
	}
	.related-posts li a:hover {
		color: #ac4142;
	}
}
.theme-base-09 {
	.sidebar {
		background-color: #d28445;
	}
	.content a {
		color: #d28445;
	}
	.related-posts li a:hover {
		color: #d28445;
	}
}
.theme-base-0a {
	.sidebar {
		background-color: #f4bf75;
	}
	.content a {
		color: #f4bf75;
	}
	.related-posts li a:hover {
		color: #f4bf75;
	}
}
.theme-base-0b {
	.sidebar {
		background-color: #90a959;
	}
	.content a {
		color: #90a959;
	}
	.related-posts li a:hover {
		color: #90a959;
	}
}
.theme-base-0c {
	.sidebar {
		background-color: #75b5aa;
	}
	.content a {
		color: #75b5aa;
	}
	.related-posts li a:hover {
		color: #75b5aa;
	}
}
.theme-base-0d {
	.sidebar {
		background-color: #6a9fb5;
	}
	.content a {
		color: #6a9fb5;
	}
	.related-posts li a:hover {
		color: #6a9fb5;
	}
}
.theme-base-0e {
	.sidebar {
		background-color: #aa759f;
	}
	.content a {
		color: #aa759f;
	}
	.related-posts li a:hover {
		color: #aa759f;
	}
}
.theme-base-0f {
	.sidebar {
		background-color: #8f5536;
	}
	.content a {
		color: #8f5536;
	}
	.related-posts li a:hover {
		color: #8f5536;
	}
}
.theme-chef-custom {
	color: #E6E6FA;
	//background-color: #333333;
	background-color: #131313;
	
	th {
		background-color: #232323;
	}
	tbody tr:nth-child(odd) td,
	tbody tr:nth-child(odd) th {
  	background-color: #323232;
  	padding: .25rem .5rem;
  	//border: 2px solid black;
}
	tbody tr:nth-child(even) td,
	tbody tr:nth-child(even) th {
	padding: .25rem .5rem;
  	//border: 2px solid white;
}
	blockquote {
	//background-color: #333333;
	background-color: #232323;
	border: 1px solid;
	color: #FFFFFF;
	//box-shadow:  5px 5px olive, 10px 10px grey, 15px 15px purple;
	box-shadow:  1px 1px olive;
	//text-shadow: 2px 2px black;
	@extend %quotebox;
}

	
	hr {
	border: 1px solid green;
	}
	
	pre {
		border: 1px solid $gray-1;
		border-radius: 2px, 4px;
		tab-size: 2;
		//width: 111%;
		//margin-left: -11%;
		//padding-left: 9%;
		//padding-right: 2%;
		background: black;
		box-shadow:  5px 5px black, 10px 10px grey, 15px 15px white;
		//text-shadow: 1px 1px green;
	}
	
	code {
	    @extend %code-style-npf;
		@extend %code-font;
		color: #00daff;
		//border: 1px solid white;
		//border-radius: 2px, 3px;
	}
	
	.site__description {
		color: #E6E6FA;
	}
	.sidebar {
		//background-color: #333333;
		background-color: #232323;
		border: 1px solid #E6E6FA;
		//box-shadow:  3px 3px red, 6px 6px white, 9px 9px purple;
	}
	.sidebar a {
		color: #E6E6FA;
	}
	.content a {
		color: green;
	}
	.related-posts li a:hover {
		color: cyan;
	}

}